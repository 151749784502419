import { withModifiers, type PropType } from 'vue'
import type { Column } from '@tanstack/vue-table'
import { Button, Icon } from '#components'

export default defineComponent({
  props: {
    column: {
      type: Object as PropType<Column<any>>,
      required: true,
    },
  },
  setup(props) {
    const isPinned = computed(() => props.column.getIsPinned())

    return () => (
      <VTooltip auto-hide={false} class="h-5 w-5">
        {{
          default: () => (
            <Button
              color={isPinned.value ? 'primary' : 'transparentPrimary'}
              class={[
                'h-5 w-5 rounded-full px-0 py-0 text-center group-hover/th:flex',
                !isPinned.value && 'hidden',
              ]}
              onClick={withModifiers(
                () =>
                  isPinned.value
                    ? props.column.pin(false)
                    : props.column.pin('left'),
                ['stop'],
              )}
            >
              <Icon name="pinned" />
            </Button>
          ),
          popper: () => (
            <span class="relative flex items-center gap-1 text-xs">
              {isPinned.value
                ? 'Unlock this column (it will move back to its original position)'
                : 'Lock this column (it will move the the beginning of the table)'}
            </span>
          ),
        }}
      </VTooltip>
    )
  },
})
